.banner {
    width: 300px;
    height: 250px;
    background-color: #f8f9fa; /* Fondo claro para integrarse con el sitio */
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #ccc;
}

.banner a {
    display: block;
    padding: 10px;
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left;
    color: #333; /* Texto oscuro para buen contraste */
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
    background-color: #e9ecef; /* Fondo claro similar al de la web */
    border-left: 5px solid #007bff; /* Borde izquierdo en azul para integración */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.banner a:nth-child(2) {
    border-left-color: #28a745; /* Segundo enlace: verde similar al logo */
}

.banner a:nth-child(3) {
    border-left-color: #ffc107; /* Tercer enlace: amarillo similar a los botones */
}

.banner a:nth-child(4) {
    border-left-color: #dc3545; /* Cuarto enlace: rojo */
}

.banner a:hover {
    background-color: #d6d8db; /* Cambia el color al pasar el mouse */
}