body {
    -webkit-text-size-adjust: 100%;
    font-family: arial, sans-serif;
    margin: 0;
}

.div {
    -webkit-box-flex: 0 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    max-width: 100%;
}

.span:last-child,
.div:last-child {
    -webkit-box-flex: 1 0;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
}

.a {
    text-decoration: none;
    text-transform: none;
    color: inherit;
    display: inline-block;
}

.span {
    -webkit-box-flex: 0 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    display: inline-block;
    overflow: hidden;
    text-transform: none;
}

.img {
    border: none;
    max-width: 100%;
    max-height: 100%;
}

.i_ {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    overflow: hidden;
}

.v_ {
    -webkit-box-flex: 1 0;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
}

.si101 {
    background-color: #214c96;
    border-radius: 25px;
    border: 5px solid #214c96;
    padding: 20px;
    color: #ffffff;
    width: 100%;
    flex-shrink: 1;
    transition: all 0.3s ease;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    overflow: hidden;
    height: auto;
    min-height: 70px; /* Ensures rows have a minimum height */
}

.si101:hover {
    font-weight: 700;
    background-color: #003366;
    text-decoration: underline;
}

.si141 {
    padding: 12px 36px 12px 24px;
    width: 100%;
    flex-shrink: 1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.si132 {
    font-weight: 700;
    font-size: 23px;
    line-height: 1.2;
    padding: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Limits text to 2 lines */
    white-space: normal; /* Allow text to wrap */
    flex-shrink: 1;
}

.si69 {
    right: 12px;
    position: absolute;
    width: 30px;
}

.si102 {
    width: 30px;
}

.si128 {
    background-color: #ffffff;
    height: 1px;
    width: 100%;
    flex-shrink: 1;
}

.si133 {
    font-size: 13px;
    padding-bottom: 5px;
    color: #676767;
}

.si135 {
    background-color: #ffffff;
    padding-bottom: 0px;
    padding-right: 0px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.rssAttrContainer {
    width: 100%;
    flex-shrink: 1;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .si101 {
        padding: 15px;
        min-height: 60px; /* Adjust minimum height for smaller screens */
    }

    .si132 {
        font-size: 18px;
    }

    .si141 {
        padding: 8px 20px;
    }

    .si69 {
        width: 25px;
        right: 8px;
    }

    .si102 {
        width: 25px;
    }
}

@media (max-width: 480px) {
    .si101 {
        padding: 10px;
        min-height: 55px; /* Further adjust for very small screens */
        max-height: 60px;
    }

    .si132 {
        font-size: 16px;
    }

    .si141 {
        padding: 6px 16px;
    }

    .si69 {
        width: 20px;
        right: 5px;
    }

    .si102 {
        width: 20px;
    }
}

/* Exact nth-of-type selectors for alternating colors */
.si101:nth-of-type(5n+1) > .si141 {
    border-left: #1f8a70 7px solid;
}

.rssAttrContainer ~ .si101:nth-of-type(5n+2) > .si141 {
    border-left: #1f8a70 7px solid;
}

.si101:nth-of-type(5n+3) > .si141 {
    border-left: #bedb39 7px solid;
}

.rssAttrContainer ~ .si101:nth-of-type(5n+4) > .si141 {
    border-left: #bedb39 7px solid;
}

.si101:nth-of-type(5n+5) > .si141 {
    border-left: #ffe11a 7px solid;
}

.rssAttrContainer ~ .si101:nth-of-type(5n+6) > .si141 {
    border-left: #ffe11a 7px solid;
}

.si101:nth-of-type(5n+2) > .si141 {
    border-left: #fd7400 7px solid;
}

.rssAttrContainer ~ .si101:nth-of-type(5n+3) > .si141 {
    border-left: #fd7400 7px solid;
}

.si101:nth-of-type(5n+4) > .si141 {
    border-left: #004358 7px solid;
}

.rssAttrContainer ~ .si101:nth-of-type(5n+5) > .si141 {
    border-left: #004358 7px solid;
}